<template>
  <div>
    <div class="row">
      <div class="col-md-10">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-start">
              <img src="/img/logo/Logo-UNJ.png" alt="" height="100" />
            </div>

            <div class="d-flex justify-content-center">
              <h4>Sistem Administrasi Umum</h4>
            </div>
            <div class="d-flex justify-content-center">
              <h4>
                Program Studi Fisika, Pendidikan Fisika, dan Magister Pendidikan
                Fisika
              </h4>
            </div>
            <hr style="height: 1px; background: black" />

            <table class="table table-borderless">
              <tr style="line-height: 1">
                <td>Nama Mahasiswa</td>
                <td>:</td>
                <td>{{ detail.student_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>NIM</td>
                <td>:</td>
                <td>{{ detail.student_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Semester ke-</td>
                <td>:</td>
                <td>{{ detail.semester_id }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Tahun ke-</td>
                <td>:</td>
                <td>{{ detail.year_id }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Program Studi</td>
                <td>:</td>
                <td>{{ detail.study_program_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Fakultas</td>
                <td>:</td>
                <td>{{ detail.faculty_name }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat</td>
                <td>:</td>
                <td>{{ detail.address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>No. Telp/Wa</td>
                <td>:</td>
                <td>{{ detail.address }}</td>
              </tr>
              <tr style="line-height: 1">
                <td>Alamat Email</td>
                <td>:</td>
                <td>{{ detail.email }}</td>
              </tr>
              <!--  -->
              <!--  -->
              <tr style="line-height: 1">
                <td>Keterangan</td>
                <td>:</td>
                <td>{{ detail.description }}</td>
              </tr>
            </table>

            <!-- <div class="d-flex justify-content-start mt-2">
              <div
                class="
                  d-flex
                  justify-content-start
                  align-items-center
                  image-container
                "
              >
                <div class="image-input-wrapper" style="position: relative">
                  <img class="image" :src="detail.sign" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: Object,
  },
  methods:{
      setStartTime(time){
        let date = new Date(time);
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        console.log(date.toLocaleTimeString('id-ID'));        
        return date.toLocaleDateString('id-ID', options) + " " + date.toLocaleTimeString('id-ID')
    }
  }
};
</script>

<style scoped>
.undot li {
  list-style-type: none;
}

.image-input-wrapper {
  width: 130px !important;
  height: 130px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>